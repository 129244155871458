import { template as template_5430896ff247469899a72de679d92d91 } from "@ember/template-compiler";
const WelcomeHeader = template_5430896ff247469899a72de679d92d91(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
