import { template as template_9942c633975e4a36abd89b07f5a66cf4 } from "@ember/template-compiler";
const FKLabel = template_9942c633975e4a36abd89b07f5a66cf4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
