import { template as template_fc798c8e56ea4f27bcc2fb1834d45ccd } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc798c8e56ea4f27bcc2fb1834d45ccd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
